import { Component } from "react";
import { v4 as uuid } from 'uuid';
import { CopyType } from "./EntryListComponent";

interface EntryViewModel{
    name:string;
    username:string;
    password:string;
    handleCopyEvent: (t:CopyType)=>void;
}

interface EntryComponentState{
    showPassword:boolean,
    showDropDown:boolean
}



export class EntryComponent extends Component<EntryViewModel, EntryComponentState> {

    constructor(props: EntryViewModel) {
        super(props);
        this.state = { showPassword: false, showDropDown: false };

    }

    render() {
        const id = uuid();
        const type = this.state.showPassword ? "text" : "password";
        const pressed = this.state.showPassword ? " active" : "";
        const display = this.state.showDropDown ? " show" : "";
        return (
            <div className="mb-3 row">
                <div className=" input-group">
                    <span className="input-group-text fixed" title={this.props.name}>{this.props.name}</span>
                    <span className="input-group-text" title="@">@</span>
                    <span className="input-group-text fixed" title={this.props.username}>{this.props.username}</span>
                    <input type={type} value={this.props.password} className="form-control form-control-sm" id={"inputPassword_" + id} readOnly />
                    
                    <div className="input-group-append btn-group" onMouseLeave={()=>this.handleDropdownClick(false)}>
                        <button className={"btn btn-outline-secondary" + pressed} type="button" onClick={(e) => this.handleShowClick()} title="View password"><i className="bi bi-eye-fill"></i></button>
                        <button className="btn btn-outline-secondary" type="button" onClick={(e) => this.handleCopyPasswordClick()} title="Copy password"><i className="bi bi-key-fill"></i></button>
                        <button className="btn btn-outline-secondary" type="button" onClick={(e) => this.handleCopyUsernameClick()} title="Copy username"><i className="bi bi-person-fill"></i></button>
                    </div>
                </div>
            </div>
        );
    }

                        /*
                        <button type="button" onClick={()=>this.handleDropdownClick()} className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" title="View menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="sr-only"></span>
                        </button>
                        <div className={"dropdown-menu"+display}>
                            <a className="dropdown-item" href="#" onClick={(e)=> this.handleCopyUsernameClick()}>Copy username</a>
                            <a className="dropdown-item" href="#" onClick={(e) => this.handleCopyPasswordClick()}>Copy password</a>
                        </div>
                        */

    handleDropdownClick(v:boolean|null=null): void {
        if(v === null)
            v = !this.state.showDropDown;
        this.setState({ ...this.state, showDropDown: v });
    }
    handleCopyUsernameClick(): void {
        this.props.handleCopyEvent(CopyType.USERNAME);
    }

    handleCopyPasswordClick(): void {
        this.props.handleCopyEvent(CopyType.PASSWORD);
    }

    handleShowClick() {
        this.setState({ ...this.state, showPassword: !this.state.showPassword });
    }
}
