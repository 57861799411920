import { AnyAction, Dispatch, MiddlewareAPI } from "@reduxjs/toolkit";
import { ContentState, GlobalState } from "./State";
import { AsyncEventHandler, EventHandler, createStateAction } from "./Events";

export class MiddlewareFactory{

    middleware(api: MiddlewareAPI<Dispatch<AnyAction>, GlobalState>): (next: Dispatch<AnyAction>) => (action: any) => any
    {
        return (next: Dispatch<AnyAction>) => (action: any) => {
            if(action instanceof EventHandler<ContentState>){
                const newState = action.createNewState(api.getState().content);
                api.dispatch(createStateAction(newState))
                return next({type:"NONE"});
            }
            
            if(action instanceof AsyncEventHandler<ContentState>){
                action.createNewState(api.getState().content, (s)=>{api.dispatch(createStateAction(s))})
                return next({type:"NONE"});
            }
            
            return next(action);
        };
    }
}