import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { MiddlewareFactory } from "./Middleware";
import { ContentState } from "./State";



function entryReducer<ContentState>(state:ContentState = new ContentState() as ContentState, action: AnyAction): ContentState {
    if(action.state && action.state !== state){
        return action.state as ContentState
    }
    return {...state} as ContentState;
}

export const store = configureStore({
  
    reducer: {
        content:entryReducer
    },
    middleware: m => m({serializableCheck:false}).concat(new MiddlewareFactory().middleware)
});
