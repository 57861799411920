import { AsyncEventHandler } from "../ReactEngine/Events";
import { ContentState } from "../ReactEngine/State";


export class AutoCloseHandler extends AsyncEventHandler<ContentState> {
    private timerActive: boolean = false;
    private timerId: NodeJS.Timeout | null = null;

    toggleTimer(startTimer: boolean) {
      this.timerActive = startTimer;
      if(!startTimer)
        this.clearTimer();
    }


    createNewState(currentState: ContentState, callback: (newState: ContentState) => void): void {
        this.clearTimer();
        if(this.timerActive){
            const timeout = 60;
            this.timerId = setTimeout(()=>{
                const state = new ContentState();
                state.notifications.push("Auto logout after "+timeout+" seconds of inactivity");
                callback(state);
            },timeout*1000);
        }
    }

    private clearTimer() {
        if (this.timerId !== null) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }
}
