import { AnyAction } from "@reduxjs/toolkit";
import { ContentState } from "./State";

export abstract class EventHandler<state>{
    abstract createNewState(currentState:state) : state
}

export abstract class AsyncEventHandler<state>{
    abstract createNewState(currentState:state, callback : (newState:state)=>void) : void
}

export interface StateAction extends AnyAction{
    type:string,
    state:ContentState
}

export function createStateAction(s:ContentState) : StateAction{
        return {
            type:"state_update",
            state:s
        };
}