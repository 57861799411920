import React from "react";
import { PropsWithChildren } from "react";
import { Component } from "react";

export interface ToggleViewModel{
    visibleIndex: number
}


export default class ToggleComponent extends Component<PropsWithChildren<ToggleViewModel>>{

    constructor(props: ToggleViewModel){
      super(props);
      this.state = {};
    }

    render(){
      const elements = React.Children.toArray(this.props.children);
      var i = this.props.visibleIndex;
      if(i < 0)
        i = 0;
      if(i>=elements.length)
        i = elements.length-1;
      const elm = elements[i];
      return (
          <div>
            {elm}
          </div>
      );
    }
  }



