import { EventHandler } from "../ReactEngine/Events";
import { ContentState } from "../ReactEngine/State";


export class ClosePasswordfileEventHandler extends EventHandler<ContentState> {
   
    createNewState(currentState: ContentState): ContentState {
        return new ContentState();
    }
}
