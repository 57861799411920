import * as db from './PWSafeDB'

export function loadFile(value, file, callbackEntry, onErrorCallback){
    if(file === undefined)
        return;

    if(value === undefined || value === "")
        value = "123123";

    var fileReader = new FileReader();
    fileReader.onload = () => {
        const buffer = fileReader.result;
        const options = {};
        const callback = (obj) => {
            
            if(obj instanceof Error){
                console.log(obj.message);
                onErrorCallback(obj);
                return;
            }

            var records = obj.records;
            var result = records.map((v)=>{return {
                title:v.title,
                username:v.username,
                email:v.emailAddress,
                url:v.URL,
                group: v.group,
                notes: v.notes,
                password:v.password}
            });
            callbackEntry(result);
        };
        new db.PWSafeDB().decrypt(buffer, value, options, callback);
    }
    fileReader.readAsBinaryString(file);
}
