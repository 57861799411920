import { connect } from "react-redux";
import EntryListComponent, { CopyType, Entry, EntryListViewModel } from "../components/EntryListComponent";
import {  InputOnloadEventHandler } from "../controller/InputOnloadEventHandler";
import { GlobalState } from "../ReactEngine/State";
import { EntryListCopyEventHandler } from "../controller/EntryListCopyEventHandler";
import PasswordFileReaderComponent, { InputViewModel } from "../components/PasswordFileReaderComponent";
import PasswordFileViewerComponent, { PasswordFileViewerViewModel } from "../components/PasswordFileViewerComponent";
import { ClosePasswordfileEventHandler } from "../controller/ClosePasswordfileEventHandler";
import ToggleComponent, { ToggleViewModel } from "../components/ToggleComponent";
import NotificationComponent, { NotificationViewModel } from "../components/NotificationComponent";
import FilterComponent, { FilterProps, FilterState, FilterType } from "../components/FilterComponent";
import { FilterEventHandler, FilterLimit } from "../controller/FilterEventHandler";
import MainPage, { MainPageProps, MainPageState } from "../components/MainPage";
import { AutoCloseHandler } from "../controller/AutoCloseHandler";

const closeHandler = new AutoCloseHandler();
export const Page = connect<MainPageState, MainPageProps, {}, GlobalState>((s:GlobalState )=>{
  closeHandler.toggleTimer(s.content.passwordfileName.trim().length !== 0);
  return {showMenu:false} as MainPageState
  },
  {
    handleUserActivity: () => {
      return closeHandler
    }
  } as MainPageProps

)(MainPage);

export const EntryListPresenter = connect<EntryListViewModel, {}, {}, GlobalState>((s:GlobalState)=>{
  return {
      entriesLoaded: s.content.passwordfileName?.length > 0,
      entries:s.content.entries.map(m=>{
        return {name:m.title, 
                password:m.password, 
                username:m.username?.length > 0 ? m.username : m.email
              } as Entry
      })
  } as EntryListViewModel
},
{
  handleCopyEventOn: (t:CopyType, e:Entry) => {
    switch(t){
      case CopyType.PASSWORD: return new EntryListCopyEventHandler("Password",e.password)
      case CopyType.USERNAME: return new EntryListCopyEventHandler("Username",e.username)
    }
    
  }
} 
)(EntryListComponent);

  

  export const ElementToggler = connect<ToggleViewModel, {}, {}, GlobalState>((s:GlobalState)=>{
    return {
       visibleIndex: (s.content.passwordfileName === "")? 0:1
    } as ToggleViewModel
  },{}  
  )(ToggleComponent);

export const PasswordFileReader = connect<GlobalState,InputViewModel>(null, {onloadHandler: (password,file)=>{
  return new InputOnloadEventHandler(password,file)
}})(PasswordFileReaderComponent);

export const PasswordFileViewer = connect<PasswordFileViewerViewModel, {}, {}, GlobalState>((s:GlobalState)=>{
  return {
      Filename: s.content.passwordfileName
  } as PasswordFileViewerViewModel
},
{
  onCloseHandler: () => {
    return new ClosePasswordfileEventHandler();
  }
}  
)(PasswordFileViewerComponent);

export const Filter = connect<GlobalState,FilterProps>(null,
{
  onFilterChange: (filterValue: string, filterType: FilterType) => {
    var type = FilterLimit.ALL;
    switch(filterType){
      case FilterType.Title: type = FilterLimit.TITLE; break;
      case FilterType.Username: type = FilterLimit.USERNAME; break;
    }
    return new FilterEventHandler(filterValue, type);
  }
}  
)(FilterComponent);

export const NotificationElement = connect<NotificationViewModel, {}, {}, GlobalState>((s:GlobalState)=>{
  return {
     notifications : s.content.notifications
  } as NotificationViewModel
},{}  
)(NotificationComponent);