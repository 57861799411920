import { Component, PropsWithChildren} from 'react';
import './App.css';
import React from 'react';


export default class ContainerComponent extends Component<PropsWithChildren>{

  render(): React.ReactNode {
    return (<div>{this.props.children}</div>);
  }
}
