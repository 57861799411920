import React from "react";
import { Component, RefObject } from "react";

export interface InputViewModel{
    onloadHandler: (password:string,file:File) => void;
}

interface InputComponentState{
  fileError:string
}

export default class PasswordFileReaderComponent extends Component<InputViewModel,InputComponentState>{
  
    private passwordRef: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    private fileRef: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    constructor(props: InputViewModel){
      super(props);
      this.state = {fileError:""};
    }

    render(){
      return (
          <form onSubmit={(e)=>{e.preventDefault(); this.handleClick()}}>
              <div className="mb-3">
                <label htmlFor="inputGroupFile02" className="form-label">Password file</label>
                <div className="input-group">
                 <input type="file" className="form-control form-control-sm" id="inputGroupFile02" accept=".psafe3" ref={this.fileRef} />
                </div>
                <div className="form-text">{this.state.fileError}</div>
              </div>
              <div className="mb-3">
                <label htmlFor="basic-url" className="form-label">Password</label>
                <div className="input-group">
                  <input type="password" className="form-control form-control-sm" id="basic-url" aria-describedby="basic-addon3"  ref={this.passwordRef} />
                </div>
              </div>
              <div className="input-group mb-3">
                <button type="button" className="btn btn-primary" onClick={(e)=>this.handleClick()} >Open</button>
              </div>
          </form>
      );
    }
  
    private handleClick(){
      var password = this.passwordRef.current?.value;
      if(!password)
        password = "";
      const file = this.fileRef.current?.files;
      if(!file || file?.length === 0){
        this.setState({...this.state, fileError:"No file selected"})
        return;
      }
      this.props.onloadHandler(password,file[0]);
      this.setState({...this.state, fileError:""})
    }
     
  }



