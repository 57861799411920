export interface GlobalState {
    content:ContentState
}

export class ContentState{
    passwordfileName : string = "";
    entries : Entry[] = [];
    allEntries : Entry[] = [];
    notifications: string[]=[];
}

export interface Entry{
    title:string,
    username: string,
    email: string,
    url: string,
    notes: string,
    group: string,
    password: string
}
