import React from "react";

export enum FilterType {
  All = "all",
  Title = "title",
  Username = "username"
}

export interface FilterProps {
  onFilterChange: (filterValue: string, filterType: FilterType) => void;
}

export interface FilterState {
  filterValue: string;
  filterType: FilterType;
}

export default class FilterComponent extends React.Component<FilterProps, FilterState> {
  constructor(props: FilterProps) {
    super(props);

    this.state = {
      filterValue: "",
      filterType: FilterType.All,
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterValueChange = this.handleFilterValueChange.bind(this);
  }

  handleFilterValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    this.setState({...this.state, filterValue: value});
    this.props.onFilterChange(value, this.state.filterType);
  }


  handleFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    const type = event.target.name as FilterType;
    this.setState({ ...this.state, filterType: type,});
    this.props.onFilterChange(this.state.filterValue, type);
  }

  render() {
    const { filterValue, filterType } = this.state;

    return (
      <form onSubmit={(e)=>e.preventDefault()}>
        <div className="mb-1">
            <label htmlFor="basic-text" className="form-label">Filter</label>
            <input
              type="text"
              placeholder="Filter by name"
              className="form-control"
              id="basic-text"
              value={filterValue}
              onChange={this.handleFilterValueChange}
            />
        </div>
        <div className="container mb-3">
            <div className="row small">
                <div className="col-3 form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={FilterType.All}
                        id={FilterType.All}
                        checked={filterType === FilterType.All}
                        onChange={this.handleFilterChange}
                        aria-label="Filter by all"
                    />
                    <label className="form-check-label" htmlFor={FilterType.All}>
                        All
                    </label>
                </div>
                <div className="col-3 form-check">
                    <input
                    className="form-check-input"
                    type="radio"
                    name={FilterType.Title}
                    id={FilterType.Title}
                    checked={filterType === FilterType.Title}
                    onChange={this.handleFilterChange}
                    aria-label="Filter by title"
                    />
                    <label className="form-check-label" htmlFor={FilterType.Title}>
                    Title
                    </label>
                </div>
                <div className="col-6 form-check">
                    <input
                            className="form-check-input"
                            type="radio"
                            name={FilterType.Username}
                            id={FilterType.Username}
                            checked={filterType === FilterType.Username}
                            onChange={this.handleFilterChange}
                            aria-label="Filter by username"
                        />
                        <label className="form-check-label" htmlFor={FilterType.Username}>
                            Username
                        </label>
                </div>
            </div>
        </div>
      </form>
    );
  }
}