import React from "react";
import { PropsWithChildren } from "react";
import { Component } from "react";

export interface NotificationViewModel{
    notifications: string[]
}


export default class NotificationComponent extends Component<NotificationViewModel>{

    constructor(props: NotificationViewModel){
      super(props);
      this.state = {};
    }

    render(){
      var i = 0;
      const arr = this.props.notifications.reverse();
      const html = arr.map(s => {return (
        <div key={i++} className="alert alert-success" role="alert">{s}</div>
      )})
      
      return (
          <div>
            {html}
          </div>
      );
    }
  }



