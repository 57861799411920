import { Component } from "react";

export interface PasswordFileViewerViewModel{
    Filename: string,
    onCloseHandler: ()=>void
}


export default class PasswordFileViewerComponent extends Component<PasswordFileViewerViewModel>{

    constructor(props: PasswordFileViewerViewModel){
      super(props);
      this.state = {};
    }

    render(){
      return (
          <form>
              <div className="mb-3">
                <label htmlFor="inputGroupFile02" className="form-label">Password file:</label>
                <div className="input-group">
                  {this.props.Filename}
                </div>
              </div>
              <div className="input-group mb-3">
                <button type="button" className="btn btn-primary" onClick={(e)=>this.handleClick()} >Close file</button>
              </div>
          </form>
      );
    }
  
    private handleClick(){
      this.props.onCloseHandler();
    }
     
  }



