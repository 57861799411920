import { Component } from "react";
import { EntryComponent } from "./EntryComponent";
import "./EntryListComponent.css"

export interface EntryListViewModel{
    entriesLoaded: boolean;
    entries: Entry[];
    handleCopyEventOn: (t:CopyType, e:Entry)=>void;
}

export enum CopyType{
    PASSWORD, USERNAME
}

export interface Entry{
    username: string;
    name:string;
    password:string;
}

export default class EntryListComponent extends Component<EntryListViewModel>{

    render(){
        const entries = this.props.entries.map((v,i)=>(
            
            <li className="list-group-item"  key={i}>
                <EntryComponent name={v.name} password={v.password} username={v.username} handleCopyEvent={(t)=>this.props.handleCopyEventOn(t,v)}/>
            </li>
        ));
        
        if(entries.length === 0 && this.props.entriesLoaded)
            return (<div>No entries</div>);

        return (
            <ul className="list-group EntryListComponent">
                {entries}
            </ul>
        );
    }
}
