import { Component } from 'react';
import './App.css';
import { ElementToggler, EntryListPresenter, Filter, NotificationElement, Page, PasswordFileReader, PasswordFileViewer } from '../presenters/Connectors';
import { Body, Menu } from './MainPage';

export default class App extends Component{

  render(){
    return (
      <main className="d-flex flex-nowrap">
        <Page>
          <Menu>
            <div id="file_open">
              <ElementToggler>
                <PasswordFileReader />
                <div>
                  <PasswordFileViewer />
                  <hr />
                  <Filter />
                </div>
              </ElementToggler>
            </div>
            <hr />
            <div className="container">
              <div className="row align-items-start">
                <div className="col">
                <NotificationElement />
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col">
                <small>Pure javascript client</small>
                </div>
              </div>
            </div>
          </Menu>
          <Body>
            <EntryListPresenter />
          </Body>
        </Page>
      </main>
    );
  }
}

