import { AsyncEventHandler } from "../ReactEngine/Events";
import { ContentState, Entry } from "../ReactEngine/State";
import { loadFile } from "../pwsafe/loader";

export class InputOnloadEventHandler extends AsyncEventHandler<ContentState>{
    password: string;
    file: File;
    constructor(password:string, file:File){
        super();
        this.password = password;
        this.file = file;
    }

    createNewState(s: ContentState, callback: (newState:ContentState)=>void){
        loadFile(this.password,this.file, (arr:Entry[])=>{
            const newState = {...s, entries:arr, allEntries:arr, passwordfileName:this.file.name, notifications:[]} as ContentState;
            callback(newState);
        }, (error:Error)=>{
            const arr = s.notifications.map(s => s);
            arr.push(error.message);
            callback({...s, notifications : arr} as ContentState)
        });
    }
}
