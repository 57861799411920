import { EventHandler } from "../ReactEngine/Events";
import { ContentState } from "../ReactEngine/State";

export class EntryListCopyEventHandler extends EventHandler<ContentState>{
    private txtToCopy: string;
    private description: string;

    constructor(txt:string, stringToCopy:string){
        super();
        this.txtToCopy = stringToCopy;
        this.description = txt;
    }

    createNewState(currentState: ContentState): ContentState {
        navigator.clipboard.writeText(this.txtToCopy);
        const newNotifications = currentState.notifications.map((n)=>{return n.toString()})
        newNotifications.push(this.description+" is now copied to the clipboard")
        return {...currentState, notifications:newNotifications};
    }
}