import { Component, PropsWithChildren, RefObject } from 'react';
import './App.css';
import React from 'react';
import ContainerComponent from './ContainerComponent';


export class Menu extends ContainerComponent{}
export class Body extends ContainerComponent{}

export interface MainPageState{
  showMenu:boolean
}
export interface MainPageProps{
  handleUserActivity : () => void;
}

export default class MainPage extends Component<PropsWithChildren<MainPageProps>,MainPageState>{

  constructor(props : MainPageProps){
    super(props);
    this.state = {
      showMenu : true
    }
  }

  render(){
    const elements = React.Children.toArray(this.props.children);
    if(elements.length !== 2)
      throw new Error("Must contain a Menu element and a Body element");
      
    const menu = elements[0];
    const body = elements[1];
  
    const menuStyle = {display: (this.state.showMenu)? "block" : "none"}
    const left = "bi-caret-left-fill";
    const right = "bi-caret-right-fill";
    const arrowClass = (this.state.showMenu)? left : right;

    return (
      <main className="d-flex flex-nowrap" onMouseMove={()=>this.handleUserActivity()}>
          <div className="text-bg-dark" style={menuStyle}>
            <div className="d-flex flex-column flex-shrink-0 p-3" style={{width: "280px"}}>
              <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none" id="top_menu_titel">
                <i className="bi bi-file-lock"></i>
                <span className="fs-4">PassSafe Online</span>
              </a>
              <hr />
              {menu}
            </div>
          </div>
          <div className="b-example-divider b-example-vr" onClick={(e)=>this.toggleMenu()}>
            <i className={"bi menu_collapse_icon "+arrowClass}></i>
          </div>
         <div className="d-flex flex-column flex-shrink-0 p-3 bg-white flex-fill overflow-auto" >
           {body}
         </div>
      </main>
    );
  }

  handleUserActivity() {
    this.props.handleUserActivity();
  }

  toggleMenu(): void {
    this.setState({...this.state, showMenu : !this.state.showMenu})
  }
}

