import { EventHandler } from "../ReactEngine/Events";
import { ContentState, Entry } from "../ReactEngine/State";


export enum FilterLimit{
    ALL, TITLE, USERNAME
}

export class FilterEventHandler extends EventHandler<ContentState> {
    filter: string;
    type: FilterLimit;
   
    constructor(filter:string, type: FilterLimit){
        super();
        this.filter = filter;
        this.type = type;
    }
    
    createNewState(currentState: ContentState): ContentState {
        var f;
        switch(this.type){
            case FilterLimit.ALL: f = (e:Entry)=>{return this.filterTitle(e) || this.filterUsername(e) || e.url?.toLowerCase().includes(this.filter?.toLowerCase())}; break;
            case FilterLimit.TITLE: f = (e:Entry)=>{return this.filterTitle(e)}; break;
            case FilterLimit.USERNAME: f = (e:Entry)=>{return this.filterUsername(e)}; break;
        }
        
        const arr = currentState.allEntries.filter(f);
        return {...currentState, entries:arr};
    }

    private filterTitle(e: Entry) {
        return e.title?.toLowerCase().includes(this.filter?.toLowerCase());
    }

    private filterUsername(e: Entry) {
        return e.username?.toLowerCase().includes(this.filter?.toLowerCase()) || e.email?.toLowerCase().includes(this.filter?.toLowerCase());
    }
}
